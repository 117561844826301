
import Hero from '@/components/PageHero/PageHero.vue';
import TextHtml from '@/components/TextHtml/TextHtml.vue';
import IconBoxes from '@/components/IconBoxes/IconBoxes.vue';
import TwoBoxes from '@/components/TwoBoxes/TwoBoxes.vue';
import TextAndImage from '@/components/TextAndImage/TextAndImage.vue';
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';
import Quote from '@/components/Quote/TheQuote.vue';
import ImageBlock from '@/components/ImageBlock/ImageBlock.vue';
import FormContact from '@/components/FormContact/FormContact.vue';
import List from '@/components/MainList/MainList.vue';
import ListQuery from '@/components/MainListQuery/MainListQuery.vue';
import ListQuerySelectedPages from '@/components/MainListQuerySelectedPages/MainListQuerySelectedPages.vue';
import VideoSection from '@/components/VideoSection/VideoSection.vue';
import ContactSection from '@/components/ContactSection/ContactSection.vue';
import GoogleMaps from '@/components/GoogleMaps/GoogleMaps.vue';
import EventBanner from '@/components/EventBanner/EventBanner.vue';
import WhitePapers from '@/components/WhitePapers/WhitePapers.vue';

export default {
	components: {
		Hero,
		TextHtml,
		Quote,
		IconBoxes,
		ImageGallery,
		TextAndImage,
		ImageBlock,
		FormContact,
		TwoBoxes,
		List,
		ListQuery,
		ListQuerySelectedPages,
		VideoSection,
		ContactSection,
		GoogleMaps,
		EventBanner,
		WhitePapers,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
};
